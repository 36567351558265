.contentTitle {
  color: var(--ion-color-primary) !important;
  /* vertical-align: text-top; */
  padding-left: 30px;
}

.txtMenu {
    position: absolute;
    padding-top: 17px;
}

h1, h2, p{
  margin-left: 40px;
}

