.welcomeImage img {
    max-height: 100%;
    overflow: hidden;
}

.IonButton {
    text-transform: none;
}

.txtMenu {
    position: absolute;
    padding-top: 17px;
}