@media screen and (max-width: 768px) {

  .cardTittle {
    font-size:12px;
  }

  .cardSubTittle {
    font-size:10px;
  }

  .cardContent {
    font-size:10px;
  }

}

.contentTitle {
  color: var(--ion-color-primary) !important;
  vertical-align: text-top;
  padding-left: 30px;
}

.Img {
  max-width: 80%;
  height: auto;
}